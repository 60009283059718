export default {
  en: {
    title: 'Settings',
    menu: {
      account: 'My Account',
      notifications: 'My Notifications',
      organisation: 'Organisation',
      approvalConfiguration: 'Supplier Approval',
      manageUsers: 'Users',
      manageUsersAndRoles: 'Users & Roles',
      supplierLabels: 'Supplier Labels',
      subEntityLabels: 'Sub-Entity Labels',
      customProperties: 'Custom Properties',
      sso: 'Single Sign-On',
      visualisation: 'Visualisation',
      networkAndCommunity: 'Network & Community',
      api: 'API Keys',
      discussions: 'Discussions',
      risks: 'Risks',
      framework: 'Framework & Add-ons',
    },
    labels: {
      email: 'Email',
      name: 'Name',
      team: 'Team',
      jobTitle: 'Job Title',
      linkedinUrl: 'LinkedIn URL',
      phone: 'Mobile Number',
      password: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      role: 'Role',
      admin: 'Admin',
      edit: 'Edit',
      view: 'View',
      modePermission: 'Mode Permission',
      client: 'Client',
      supplier: 'Supplier',
      client_supplier: 'Client & Supplier',
      federated: 'Federated',
      supplierVisibility: 'Supplier Visibility',
    },
    placeholder: {
      team: 'Select your team...',
      userTeam: 'Select team...',
    },
    buttons: {
      save: 'Save',
      changePassword: 'Change Password',
      useSMS: 'Use SMS',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      confirm: 'Confirm',
      addUser: 'Add User',
      resend: 'Resend Password',
    },
    restrictDropdown: {
      header: 'Which suppliers should this user see?',
      allSuppliers: 'All Suppliers',
      allSuppliersDescription: "Every supplier you're connection with.",
      assignedSuppliers: 'Assigned Suppliers',
      assignedSuppliersDescription: "Only suppliers they've been assigned to.",
    },
    error: {
      updateDetails: 'Failed updating account details',
      delete: 'Failed deleting user',
      userCreated: 'Error creating user',
      userAlreadyExists: 'Email already in use',
      resend: 'Failed sending new temporary password',
    },
    message: {
      authenticatorStep:
        'Scan the QR code using your authenticator app, or copy in the secret key.',
      authenticatorStepCode: 'Enter the generated code below.',
      secretKeyTooltip:
        "If you're unable to scan the above QR code, please enter this secret code into your app manually.",
      detailsUpdated: 'Details updated',
      delete: 'User has been deleted',
      userCreated: 'User has been created',
      resend: 'Temporary password has been resent',
      passwordRules:
        'Password must be at least 8 characters and contain at least 1 uppercase character, 1 number and 1 symbol',
      passwordConfirm: 'Passwords do not match',
      passwordChanged: 'Password changed',
    },
    account: {
      details: 'Details',
      password: 'Change Password',
      secretKey: 'Secret Key',
      mfa: 'Configure 2FA',
      ownDetailsUpdated: 'Your details have been updated',
    },
    manageUsers: {
      users: 'Manage Users',
      title: 'Manage Users & Roles',
      descTitle: 'Each of your users has a role that determines their permissions on Risk Ledger.',
      desc: "<b>View</b>: Can only view the data available to your organisation.<br><b>Edit</b>: Like View, but can also do most activities such as editing assessment, posting comments, managing policies, and more.<br><b>Admin</b>: Like Edit, but can also manage users and organisation-wide settings.<br><b>Lead</b>: Your organisation's first user, with same permissions as Admin.",
      deleteTitle: "Delete user '{name}'?",
      deleteDescription: 'Are you sure you want to delete this user? This action is permanent.',
      reasonPlaceholder: 'Why are you deleting this user?',
      addNewUser: 'Add new User',
      addRole: 'Create Role',
      columns: {
        name: 'Name',
        email: 'Email',
        role: 'Role',
        visibility: 'Supplier Visibility',
        actions: 'Actions',
      },
      userUnregistered: "User hasn't signed up.",
      resendPassword: 'Resend password',
      exportCSV: 'Export CSV',
      exportUser: 'User',
      exportRole: 'Role',
      exportUserHeaders: {
        name: 'Name',
        email: 'Email',
        role: 'Role',
        supplierVisibility: 'Supplier Visibility',
        jobTitle: 'Job Title',
        team: 'Team',
        createdAt: 'Created At',
        deletedAt: 'Deleted At',
        deleted: 'Deleted',
      },
      exportUserValues: {
        assignedUsers: 'Assigned Users',
        allSuppliers: 'All Suppliers',
      },
      exportRoleHeaders: {
        roleName: 'Role Name',
        assignedUsers: 'Number of Assigned Users',
        createdAt: 'Created At',
      },
    },
    manageRoles: {
      usersWithRole: 'Not used | 1 User | {count} Users',
      edit: 'Edit',
      columns: {
        name: 'Role',
        assignedUsers: 'Assigned Users',
        actions: 'Actions',
      },
      disabledReasons: {
        default: 'This role is a managed role',
        sso: 'This role is used for Single Sign-On provisioning',
      },
      defaultProvisioning: 'Default provisioning role. You can update that',
      here: 'here',
    },
    addRole: {
      intro: 'Choose an existing role to copy or start one from scratch.',
      step: 'Step {step}/2',
      roles: {
        blankTitle: 'Blank',
        blankDescription: 'Start from scratch with no permissions preselected.',
        viewDescription: 'Basic permissions have already been set but can be customised further.',
        editDescription:
          "More advanced permissions have been set including everything from 'View'.",
        customDescription: 'All permissions you’ve previously set are included.',
      },
      create: 'Create Role',
      roleName: 'Role name',
      roleNamePlaceholder: 'Set role name',
      federatedPermissions: 'Federated permissions',
      clientPermissions: 'Client permissions',
      clientPermissionsSubtitle:
        'Granting permissions here allows users access to the client side of the platform.',
      supplierPermissions: 'Supplier permissions',
      supplierPermissionsSubtitle:
        'Granting permissions here allows users access to the supplier side of the platform.',
      settingsPermissions: 'Settings permissions',
      nameTaken: 'Please choose a unique name for this role',
      successCta: 'Custom role created!',
      errorNameTaken: 'Failed to create role - name already taken',
      errorMisc: 'Failed to create role for an unknown reason',
      learnMore: 'Learn More',
      searchPlaceholder: 'Filter permissions...',
    },
    editRole: {
      title: 'Edit role',
      successCta: 'Custom role updated!',
      errorNameTaken: 'Failed to edit role - name already taken',
      errorMisc: 'Failed to edit role for an unknown reason',
      save: 'Save',
    },
    org: {
      info: 'Basic Information',
      legalName: 'Legal Name',
      companyNumber: 'Company Registration Number',
      tradingNames: 'Trading Names',
      tradingNamesTooltip: 'Press Enter to submit each entry.',
      website: 'Website',
      businessPhoneNumber: 'Business Phone Number',
      sector: 'Sector',
      office: 'Registered Office',
      streetAddress: 'Street Address',
      city: 'City',
      postCode: 'Postcode/ZIP Code',
      country: 'Country',
      save: 'Save Changes',
      saveFailed: 'Saving failed, please try again.',
    },
    teams: {
      sales: 'Sales',
      security: 'Security',
      compliance: 'Compliance',
      procurement: 'Procurement',
      legal: 'Legal',
      esg: 'ESG',
      it: 'IT',
      other: 'Other',
    },
    help: {
      teams: 'Please select the team that best describes your role.',
      modePermission: 'Please select the platform side you would like to give the user access to.',
      client: 'The user only has access to the client side of the platform.',
      supplier: 'The user only has access to the supplier side of the platform.',
      client_supplier: 'The user has access to both client and supplier sides of the platform.',
      role: 'Your role on Risk Ledger, only admin users are able edit this.',
    },
    networkAndCommunity: {
      networkVisualisation: {
        title: 'Enable Network Visualisation',
        description1:
          'By enabling visualisation, you will be able to see the names of all organisations within your supply chain, including 4th, 5th and 6th parties.',
        description2: 'Your clients will also be able to see the names of your suppliers.',
        description3:
          'Your supply chain will appear to other organisations on the network and any communities of which you are a member.',
      },
      excludeSuppliers: {
        title: 'Exclude Suppliers',
        description:
          'Select which suppliers you would like to exclude from the visualisation, the names of these suppliers will not be shared with your clients.',
        placeholder: 'Search...',
      },
      privateConnections: {
        title: 'Private Suppliers',
        description:
          'Set individual suppliers as private if they are confidential or subject to non-disclosure agreements. This will prevent them from appearing in network visualisations and communities.',
        supplierName: 'Supplier Name',
        toggledPrivateBy: 'Added By',
        addedBy: 'Added by',
        addButton: 'Hide Supplier',
        privateConnection: 'Private Supplier',
        markAsPrivateButton: 'Mark as private',
        searchPlaceholder: 'Search from your suppliers...',
        closeButton: 'Done',
      },
      fetchError: 'Failed to get network configuration.',
      updateError: 'Failed to update network configuration.',
    },
    frameworkDefaultConfig: {
      title: 'Framework & Add-on Defaults',
      levelsTitle: 'Frameworks',
      levelsDescription: 'Choose the default number of controls you want your suppliers to answer.',
      addOnsTitle: 'Add-ons (optional)',
      addOnsDescription:
        "Choose any additional default assessment areas you'd like suppliers to answer.",
    },
  },
};
