import { useMutation, useQuery, type MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useStore } from 'vuex';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import type { Answer } from '@/types/models/answer';
import { useFramework, useCombinedFrameworkConfig } from '@/modules/framework/composables';
import useFeatureFlags from '@/composables/feature-flags';
import api from './api';

export const ASSESSMENT_ANSWERS_QUERY = '/assessment/answers';

export type ControlAnswersResponse = Record<string, Answer>;

export function useControlAnswers(options?: QueryOptions<ControlAnswersResponse>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [ASSESSMENT_ANSWERS_QUERY],
      async queryFn() {
        const res = await api.getControlAnswers();
        return res.data;
      },
    }),
  );

  function getByAnswerID(answerID: string) {
    if (!query.data.value) return null;

    for (const controlAnswer of Object.values(query.data.value)) {
      if (controlAnswer.answerID === answerID) {
        return controlAnswer;
      }
    }

    return null;
  }

  return { ...query, getByAnswerID, controlAnswers: computed(() => query.data.value || {}) };
}

export function useScopingComplete(
  options?: MutationObserverOptions<void, AxiosError, void, null>,
) {
  const store = useStore();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        await api.completeScopingQuestions();
      },
      async onSuccess(_data) {
        trackEvent('scoping:completed');
        // Revalidate assessment status
        await store.dispatch('assessment/getAssessmentStatus');
      },
    }),
  );
}

export const NEW_IN_SCOPE_DOMAINS_QUERY = '/quickanswer/newInScopeDomains';
export function useNewInscopeDomains(options?: QueryOptions<string[]>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [NEW_IN_SCOPE_DOMAINS_QUERY],
      async queryFn() {
        const res = await api.getNewInscopeDomains();
        return res.data;
      },
    }),
  );

  return { ...query, newInscopeDomains: computed(() => query.data.value || []) };
}

export function useRequiringAttention() {
  const store = useStore();
  const { ff } = useFeatureFlags();

  const { sortedControls, sortedDomains, domainsByID } = useFramework();

  const { frameworkRequiredDomainIDs, frameworkRequiredControlIDs } = useCombinedFrameworkConfig();

  const controlsRequiringAttention = computed(() => {
    const answers = store.state.assessment.controlAnswers;
    const outOfScope = store.getters['assessment/outOfScope'];
    const isReassessing = store.getters['assessment/isReassessing'];
    const scopingAnswers = store.state.assessment.scopingAnswers;

    return sortedControls.value
      .filter((control) => {
        if (
          ff('frameworkAddOns') &&
          !frameworkRequiredControlIDs.value.includes(control.controlID)
        ) {
          return false;
        }

        if (control.deprecated || outOfScope[control.domainID])
          // Ignore deprecated and out of scope
          return false;

        // Add controls that changed meaning
        const answer = answers[control.controlID];
        if (answer && !answer.notApplicable && answer.unconfirmedControlUpdate) {
          return true;
        }

        // If reassessing then also add unanswered applicable controls (whose scoping question was answered)
        if (isReassessing) {
          if (
            !domainsByID.value[control.domainID].scopingQuestion ||
            scopingAnswers[control.domainID]
          ) {
            if (!answer || (!answer.answer && !answer.notApplicable)) {
              return true;
            }
          }
        }

        return false;
      })
      .map((control) => control.controlID);
  });

  const scopingQuestionsRequiringAttention = computed(() =>
    sortedDomains.value
      .filter(
        (domain) =>
          !domain.deprecated &&
          domain.scopingQuestion &&
          !store.state.assessment.scopingAnswers[domain.domainID] &&
          (!ff('frameworkAddOns') || frameworkRequiredDomainIDs.value.includes(domain.domainID)),
      )
      .map((domain) => domain.domainID),
  );

  const totalNumberRequiringAttention = computed(
    () => controlsRequiringAttention.value.length + scopingQuestionsRequiringAttention.value.length,
  );

  return {
    controlsRequiringAttention,
    scopingQuestionsRequiringAttention,
    totalNumberRequiringAttention,
  };
}
