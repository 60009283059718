<script lang="ts" setup>
import useFeatureFlags from '@/composables/feature-flags';

const flags = useFeatureFlags();
</script>

<template>
  <BasePopover placement="top-start">
    <template #default>
      <div class="menu-launcher" tabindex="-1">
        <BaseIcon class="menu-icon" name="flag" colour="white" size="large" />
      </div>
    </template>
    <template #popover>
      <div class="popover-content">
        <h3 class="mb-4x mt-0">{{ $t('components.ff.featureFlags') }}</h3>
        <template v-for="[key, item] in Object.entries(flags.flagsWithOverrides)" :key="key">
          <div :uid="key" class="flex">
            <BaseSwitch
              :model-value="item.enabled"
              :label="key"
              class="mb-4x flex-1"
              @update:model-value="(val) => flags.setOverride(key, val)"
            />
            <span v-if="item.local" class="ml-1.5x">
              <span v-tooltip="$t('components.ff.localStorage')">
                <BaseIcon name="folder_open" />
              </span>
            </span>
          </div>
        </template>
        <a @click="flags.resetAllOverrides()">{{ $t('components.ff.reset') }}</a>
      </div>
    </template>
  </BasePopover>
</template>

<style scoped>
/* styles mostly copied from intercom bubble, tehe */
.menu-launcher {
  padding: 0;
  margin: 0;
  border: none;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: theme('colors.grey-darkest');
  cursor: pointer;
  box-shadow:
    0 1px 6px 0 rgb(0 0 0 / 6%),
    0 2px 32px 0 rgb(0 0 0 / 16%);
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1);
  box-sizing: content-box;
}

.menu-launcher:hover {
  transform: scale(1.1);
}

.menu-launcher:active {
  transform: scale(0.85);
}

.menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  transition:
    transform 100ms linear,
    opacity 80ms linear;
}

.popover-content {
  width: 450px;
  min-height: 220px;
  max-height: calc(100vh - 100px);
  box-sizing: border-box;
  padding: 20px;
  overflow-y: scroll;
}
</style>
