<script lang="ts" setup>
import type { MaterialSymbolName } from '@/types/material-symbol-names';

export type IconColour =
  | 'default'
  | 'white'
  | 'disabled'
  | 'primary'
  | 'alert'
  | 'warning'
  | 'info'
  | 'purple';

defineOptions({ name: 'BaseIcon' });

const props = defineProps<{
  name?: MaterialSymbolName;
  colour?: IconColour;
  size?: 'small' | 'medium' | 'large' | 'larger' | 'huge' | 'inherit';
  outlined?: boolean;
}>();

// This is here for legacy icons using _outline in the name
// TODO: update all of these icons and remove this code
const outlined = computed(() => props.name?.endsWith('_outline') || props.outlined);
const name = computed(() =>
  props.name?.endsWith('_outline') ? props.name.slice(0, -8) : props.name,
);
</script>

<template>
  <i
    translate="no"
    :class="['material-symbols-outlined', { outlined }, 'base-icon', size || 'medium', colour]"
  >
    {{ name }}
  </i>
</template>

<style scoped>
.base-icon {
  line-height: inherit;
  vertical-align: bottom;
  user-select: none;
  color: inherit;
  font-variation-settings: 'FILL' 1;

  &.outlined {
    font-variation-settings: 'FILL' 0;
  }

  /* These classes are nested under .base-icon for the additional specificity */
  &.default {
    color: theme('colors.default');
  }

  &.white {
    color: theme('colors.white');
  }

  &.disabled {
    color: theme('colors.disabled');
  }

  &.primary {
    color: theme('colors.primary');
  }

  &.alert {
    color: theme('colors.error');
  }

  &.warning {
    color: theme('colors.warning');
  }

  &.info {
    color: theme('colors.info');
  }

  &.purple {
    color: theme('colors.purple-600');
  }

  &.small {
    font-size: 13px;
  }

  &.medium {
    font-size: 18px;
  }

  &.large {
    font-size: 20px;
  }

  &.larger {
    font-size: 35px;
  }

  &.huge {
    font-size: 60px;
  }

  &.inherit {
    font-size: inherit;
  }
}
</style>
