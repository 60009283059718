import { request } from '@/api/request';
import type { Answer } from '@/types/models/answer';

const getAssessmentStatus = () => request('GET', `/assessment/status`);

const getScopingAnswers = () => request('GET', '/assessment/scopinganswers');

const changeScopingAnswer = (domainID, answer, notes) =>
  request('PUT', `/assessment/scopinganswers/${domainID}`, { answer, notes });

const completeScopingQuestions = () => request('PUT', '/assessment/scopinganswers/complete');

const getControlAnswers = () => request('GET', '/assessment/answers');

const answerControl = (controlID, answer) =>
  request<Answer>('PUT', `/assessment/answers/${controlID}`, answer);
const confirmControlUpdate = (controlID) =>
  request('POST', `/assessment/answers/${controlID}/confirmcontrolupdate`);

const submitAssessment = () => request('POST', `/assessment/complete`);

const getControlBookmarks = () => request('GET', '/assessment/controlbookmarks');

const bookmarkControl = (controlID) => request('POST', `/assessment/control/${controlID}/bookmark`);

const unbookmarkControl = (controlID) =>
  request('POST', `/assessment/control/${controlID}/unbookmark`);

const getNewInscopeDomains = () => request<string[]>('GET', '/quickanswer/newInScopeDomains');

export default {
  getAssessmentStatus,
  getScopingAnswers,
  changeScopingAnswer,
  completeScopingQuestions,
  getControlAnswers,
  answerControl,
  submitAssessment,
  confirmControlUpdate,
  getControlBookmarks,
  bookmarkControl,
  unbookmarkControl,
  getNewInscopeDomains,
};
